import React, { Component, Fragment } from 'react';
import { SinglePageLayout, GalleryItem, ItemFilter } from '../../components';
import Masonry from 'react-masonry-component';
import { Link } from 'gatsby';
import { parse } from 'query-string';
import { navigate } from '@reach/router';
import '../../contentful/fragments';

const addQuerystring = (url, filter) =>
  `${url}${filter === 'all' ? '' : `?category=${filter}`}`;

const masonryOptions = {
  transitionDuration: 600,
  percentPosition: false,
  gutter: 0,
};

const projectCategories = [
  'all',
  'commercial',
  'content',
  'corporate',
  'cases',
  'music',
  'feature',
];

const GalleryContents = ({
  gallery,
  projectCategories,
  activeFilter,
  masonryOptions,
  path,
  visibleItems,
}) => {
  return (
    <Masonry className={'gridContainer__galleryGrid'} options={masonryOptions}>
      {visibleItems.map(data => (
        <GalleryItem
          item={data}
          linkLabel={gallery.linkLabel}
          categoryLabel={
            gallery.filterNames[projectCategories.indexOf(data.category)]
          }
          queryString={addQuerystring(
            `${path}/${data.sluggedName}/`,
            activeFilter
          )}
          key={data.sluggedName}
        />
      ))}
    </Masonry>
  );
};

class Gallery extends Component {
  constructor(props) {
    super(props);

    const { data } = props;

    this.search = parse(props.location.search).category;

    const projects = !this.search
      ? data.projects
      : data.projects.filter(item => item.category === this.search);

    this.state = {
      filter: this.search || 'all',
      visibleItems: projects,
    };

    this.gallaryGridRef = React.createRef(); // Create a ref object
  }

  handleFilterChange = filter => {
    this.setState({
      filter,
      visibleItems:
        filter === 'all'
          ? this.props.data.projects
          : this.props.data.projects.filter(item => item.category === filter),
    });

    navigate(addQuerystring(this.props.path, filter));
  };

  render() {
    const { path } = this.props;
    const { text } = this.props.pageContext;

    const goToTop = () => {
      this.gallaryGridRef.current.scrollTo(0, 0);
    };

    return (
      <SinglePageLayout>
        <ItemFilter
          text={text ? text.gallery : ''}
          filters={projectCategories}
          setFilter={filter => this.handleFilterChange(filter)}
          activeFilter={() => this.state.filter}
        />
        <GalleryContents
          path={path}
          gallery={text ? text.gallery : ''}
          projectCategories={projectCategories}
          activeFilter={this.state.filter}
          setFilter={filter => this.handleFilterChange(filter)}
          masonryOptions={masonryOptions}
          visibleItems={this.state.visibleItems}
        />
      </SinglePageLayout>
    );
  }
}

export default Gallery;
