import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../Layout/Layout';
import Gallery from './Gallery';

export default props => {
  const data = {
    projects: props.data.projects.nodes,
  };
  return (
    <Layout>
      <Gallery {...props} data={data} />
    </Layout>
  );
};

export const query = graphql`
  query($locale: String!) {
    projects: allContentfulProjectDetailPage(
      filter: { node_locale: { eq: $locale } }
      sort: { fields: [createdAt], order: DESC }
    ) {
      nodes {
        category
        sluggedName
        client
        projectName
        previewImages {
          fluid(quality: 90, maxWidth: 1920) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
      }
    }
  }
`;
